import React from 'react';
import { Link } from "gatsby"
import Button from 'react-bootstrap/Button'

import { ItinDetailsContainer, ActiLengthParens } from "./ItinContainer.styles"
import FeatureImage from "components/FeatureImage"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ItinVariations from "components/ItinVariations"
import ItinDistance from "components/ItinDistance"

import { ItineraryTypeMark } from "styles/GlobalStyles.js"

import CountItinPhotos from 'components/CountItinPhotos'

// functions
import GetActivityTypeAsString from 'functions/GetActivityTypeAsString'

// outputs a columned info table about the itinerary, including "view" buttons for "highlights, activities and photographs"
const ItinDetailsTable = ({itin, detailLevel, layout, viewMode, headerText }) => {
    console.log ('current itinerary (#aer93nfdn)', itin)

    const itinHighlights   = itin.fields.slug_highlights
    const itinPhotographs  = itin.fields.slug
    const itinActivities   = itin.fields.slug_activities
    const activityLength   = GetActivityTypeAsString (itin.field_activity_length)

    // const layout = 'large'
    var left_lg, right_lg, left_md, right_md, left_sm, right_sm
    
    if (layout == 'large') {
        left_lg   = 3
        right_lg  = (12 - left_lg)
    
        left_md   = 4
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }
    
    if (layout == 'compact') {
        left_lg   = 5
        right_lg  = (12 - left_lg)
    
        left_md   = 5
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }

    // are they in "highlight mode" or "itinerary mode"
    const viewModeRenderer = (viewMode) => {

        const highlightCount    = itin.relationships.field_itin_featured_locations.length
        const activityCount     = itin.relationships.field_itinerary_activities.length
        const photographCount   = CountItinPhotos (itin)
        // const activityCount = 4
        
        // #1: Set default buttons
        var viewHighlightsButton = () => {return (
            <Link to={itinHighlights}>
                <Button size="sm" className="itinHighlights">
                    View Highlights ({highlightCount})
                </Button>
            </Link>
        )}
        
        var viewActivitiesButton = () => { return (
            <Link to={itinActivities}>
                <Button size="sm" className="itinPlan">
                    View Activities ({activityCount})
                </Button>
            </Link>
        )}
        
        var viewPhotographsButton = () => { return (
            <Link to={itinPhotographs}>
                <Button size="sm" className="itinPhotographs">
                    View Photos ({photographCount})
                </Button>
            </Link>
        )}
        
        // #2: Find the currently active button and disable it.
        if (viewMode == 'none_choosen') {
            // do nothing
        }

        if (viewMode == 'highlights') {
            viewHighlightsButton = () => { return (
                <Button size="sm" className="itinHighlights" active disabled>
                    View Highlights ({highlightCount})
                </Button>
            )}
        }

        if (viewMode == 'activites') {
            viewActivitiesButton = () => { return (
                <Button size="sm" className="itinPlan" active disabled>
                    View Activities ({activityCount})
                </Button>
            )}
        }

        if (viewMode == 'photographs') {
            viewPhotographsButton = () => { return (
                <Button size="sm" className="itinPhotographs" active disabled>
                    View Photos ({photographCount})
                </Button>
            )}
        }
        
        if (viewMode == 'photographs') {
            // ?
        }
        
        // return viewmode: "itinerary mode"
        return (
            <>
                { viewHighlightsButton() }{ ' ' }
                { viewActivitiesButton() }{ ' ' }
                { viewPhotographsButton() }
            </>
        )
    }
    
    var variantItins = itin.relationships.field_variant_itineraries
    
    console.log('detailLevel (#4tgsksdmc)', detailLevel)
    return (
        <>
            { detailLevel == "detailed" &&
                <>
                    <FeatureImage featureImage={itin.relationships.field_itin_feature_image} aspectRatio='3x1' />
                </>
            }
            
            <ItinDetailsContainer>
                {/* { headerText && 
                    <Row>
                        <Col className="Right" xs={12} md={12} lg={12}>
                            <h2>{headerText}</h2>
                        </Col>
                    </Row>
                } */}
                {/* <Row>
                    <Col className="Right" xs={12} md={12} lg={12}>
                        <FeatureImage featureImage={itin.relationships.field_itin_feature_image} />
                    </Col>
                </Row> */}
                <Row>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        Distance:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        <ItinDistance itin={itin} />
                    </Col>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        Activity length:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        {activityLength} <ActiLengthParens>(including travel time)</ActiLengthParens>
                    </Col>
                </Row>
                { console.log ("in ItinDetailTable (#4509un34): variantItins = ", variantItins)}
                { detailLevel == 'detailed' && 
                <>
                    <Row>
                        <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                            Itinerary variations:
                        </Col>
                        <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                            { variantItins.length == 0 && (
                                <span className="deemphasized">[ none ]</span>
                            )}
                            { variantItins.length > 0 && (
                                <ItinVariations variants={variantItins} selected={itin} />
                            )}
                        </Col>
                    </Row>
                </>
                }
                <Row>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        View mode:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg} style={{"padding-top": '0px'}}>
                        {viewModeRenderer(viewMode)}
                    </Col>
                </Row>

                { detailLevel == 'teaser' && 
                <>
                </>
                }
            </ItinDetailsContainer>
        </>
    )
};

export default ItinDetailsTable;