// returns the "activity length"
// todo: change function name to some more appropriate
const GetActivityTypeAsString = (activityCode) => {
    console.log('in: GetActivityTypeAsString() #asd3gg. GetActivityTypeAsString: ', activityCode)

    switch (activityCode) {
        case '1_hour':
            return '1 hour'
        case '2_hours':
            return '2 hours'
        case '3_hours':
            return '3 hours'
        case '4_hours':
            return '4 hours'
        case '5_hours':
            return '5 hours'
        case '6_hours':
            return '6 hours'
        case '12 hours':
            return '12 hours'
        case 'full_day':
            return 'Full day'
        case '1_night':
            return '1 night (2 days)'
        case '2_night':
            return '2 nights (3 days)'
        case '3_night':
            return '3 nights (4 days)'
        case '4_night':
            return '4 nights (5 days)'
        case '5_night':
            return '5 nights (6 days)'
        case '6_night':
            return '6 nights (7 days)'
        default:
            console.log ('unknown activityCode: (#sdewefw4)', activityCode)
            return 'error (#mdd321)'
    }
};

export default GetActivityTypeAsString;