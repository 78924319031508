// count the number of photographs in the activities of an itinerary
const CountItinPhotos = ( itin ) => {
    console.log ('itin (#4wec9nc): ', itin )
    const activities = itin.relationships.field_itinerary_activities

    var photographCount = activities.reduce((a,v) => {
        if (v.relationships == null) {
            // keep a's value
            // a = a
        } else {
            a = a + v.relationships.field_step_photographs.length
        }

        return a
    }
    , 0 )

    if (photographCount == null) photographCount = 0
        
    console.log('(#48fncwinwe): ', photographCount)
    
    return photographCount
}

export default CountItinPhotos;