import 'bootstrap/dist/css/bootstrap.min.css'
import React from "react"
import { Link } from "gatsby"
import { ItinWrapper } from "./styles"

const ItinVariations = ({ variants, selected }) => {
    console.log('in ItinVariants. variants (#rrimsoos3): ', variants)
    console.log('in ItinVariants. selected (#rrimsoos3): ', selected)
    var selectedId = selected.drupal_internal__nid
    
    return (
        <div>
            <ItinWrapper>
                <span href="" className="badge badge-dark">{selected.title}</span>
            </ItinWrapper>
            {variants.map(({ drupal_internal__nid, title, fields }, i) => {
                // console.log('Variants itin: ', drupal_internal__nid)
                return (
                    <>
                        { // only show if the variant is not the same as the selected (current) itin, as this is displayed earlier. 
                        selectedId !== drupal_internal__nid && (
                        <ItinWrapper>
                            <Link to={fields.slug_highlights} className="badge badge-secondary">
                                {title}
                            </Link>
                        </ItinWrapper>
                        )}
                    </>
                )
            })}
        </div>
    )
}

export default ItinVariations