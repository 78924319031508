import styled from "styled-components"
import { CustomContainer } from 'styles/CustomContainer.styles'
import { colors } from 'styles/GlobalStyles.js'

export const ItinDetailsContainer = styled(CustomContainer)`
  /* border: 1px solid #0f0; */
  /* padding: 2px 7px; */

  .Left {
    /* border: 1px solid #0f0; */
    background-color: ${colors.legendItinerary};
    margin-bottom: 4px;
    color: #000;
  }

  .Right {
    /* border: 1px solid #0f0; */
    padding-left: 10px;
  }
`

export const ActiLengthParens = styled.span`
  font-size:  .8em;
  color:      #999;
  
`