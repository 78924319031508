import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import ItineraryTeaser from "components/ItineraryTeaser"
// import PageNavigation from "components/PageNavigation"

const IndexPage = ({ data, pageContext }) => {
  const itins = data.allNodeItinerary.edges
  console.log('all itins (#40fd): ', itins)
  console.log('(#werg3489nvwer)', process.env.GATSBY_GOOGLE_MAPS_API_KEY)
  return (
    <Layout>
      <SEO title="List of itineraries" />
      <main>
        {itins.map(({ node }, i) => {
          console.log('current itin (#fowkwc): ', node)
          
          return (
            <ItineraryTeaser itin={node} />
          )
        })}
      </main>
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
query ListAllItineraries3 {
  allNodeItinerary(sort: {order: DESC, fields: created}, filter: {}) {
    edges {
      node {
        __typename
        id
        drupal_internal__nid
        fields {
          slug
          slug_highlights
          slug_activities
        }
        path {
          alias
        }
        title
        body {
          value
          processed
        }
        field_activity_length
        field_distance_in_time_
        field_itin_transport_mode
        relationships {
          field_starting_location {
            drupal_id
            title
            fields {
              slug
            }
          }
        
          field_itin_featured_locations {
            ... on node__photograph {
              drupal_id
              drupal_internal__nid
            }
          }
          field_itinerary_activities {
            drupal_internal__nid
            relationships {
              field_step_photographs {
                drupal_internal__nid
              }
            }
          }

          field_itin_feature_image {
            relationships {
              field_photo_images {
                drupal_internal__fid
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          field_itin_featured_activity {
            id
            title
            relationships {
              field_activity_feature_photo {
                id
                title
                relationships {
                  field_photo_images {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`