import React from "react"
import { Link } from "gatsby"
// Icons
// import DateIcon from "images/calendar.svg"
// import TimeIcon from "images/watch.svg"
// Styles
import {
  CardWrapper,
  TextWrapper,
} from "./ItineraryCard.styles"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import sanHTML from 'functions/sanitizeHTML'
import { CustomContainer } from "styles/CustomContainer.styles"
import ItinDetailsTable from "components/ItinDetailsTable"

import FeatureImage from "components/FeatureImage"

import { ItineraryTypeMark } from 'styles/GlobalStyles.js' 

const ItineraryTeaser = ({ itin }) => {
  console.log ("itineraryTeaser component: itin = ", itin)
  // const key               = itin.id
  const body              = itin.body
  const title             = itin.title || "No title"
  // const transportMode     = itin.field_itin_transport_mode
  // const distanceInTime    = itin.field_distance_in_time_
  var startLocation       = itin.relationships.field_starting_location.title || ""
  const relationships     = itin.relationships
  // const itinLength        = itin.field_activity_length
  // const slug              = itin.fields.slug
  const hightlightsSlug   = itin.fields.slug_highlights
  
  var desc = ''

  if (body !== null) {
      desc = body.processed
  } else {
      desc = '[no description]'
  }

  const lengthAllowed = 383     // if changing this, make sure to update Drupal: itinerary (content type) > body > help message
  desc = desc.length > lengthAllowed ? desc.substring(0, lengthAllowed-3) + "..." : desc

  const featuredPhoto = relationships.field_itin_feature_image

  if (startLocation) {
    startLocation = "from " + startLocation
  }

  console.log('feature photo (#coand23): ', featuredPhoto, ". Itin id: ", itin.drupal_internal__nid, title)
  
  // const containerStyles = {
  //   width: "100%",
  //   border: "1px solid #300",
  //   color: "#fff",
  //   borderColor: "#00f"
  // }
  
  return (
    <CardWrapper>

      <CustomContainer style={{width: "100%"}}>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Link to={`${hightlightsSlug}`}>
              <ItineraryTypeMark>Itinerary</ItineraryTypeMark>
              <h2>{title}</h2>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={8}>
            <FeatureImage featureImage={featuredPhoto} aspectRatio='1x1' />
          </Col>
          <Col xs={12} md={12} lg={4}>
            
            <TextWrapper>
              <ItinDetailsTable itin={itin} detailLevel={"teaser"} layout={"compact"} viewMode={"none_choosen"} />

              <div>
                <h3>Description:</h3>
                <div dangerouslySetInnerHTML={{ __html: sanHTML(desc) }} />
              </div>
            </TextWrapper>
          </Col>
        </Row>
      </CustomContainer>
      
    </CardWrapper>
  )
}

export default ItineraryTeaser