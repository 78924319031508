import React from "react"

// returns a string like: "3 hours drive from Melbourne"
// don't use it unless the itinerary has a start location
const ItinDistance = ({itin}) => {
    console.log('itin distance > itin: (#sdcni9df)', itin)
    // const typeName = itin.__typename
    // if (typeName != 'node__itinerary') {
    //     throw new Error ('the parameter provided to ItinDistance() is not an itinerary. It\'s type: ' + typeName)
    // }

    if (!itin.relationships.field_starting_location) {
        throw new Error (`the itinerary: ${itin.title} does not have a starting location`)
    }
    
    var startLocation = itin.relationships.field_starting_location.title || ""


    if (startLocation) {
        startLocation = "from " + startLocation
    }

    return (
    <div>
        {itin.field_distance_in_time_ + ' '} 
        {itin.field_itin_transport_mode + ' '} 
        {startLocation} 
    </div>
    )
}

export default ItinDistance